import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const RegistrationPeriodMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "RegistrationPeriodRegistrationPeriodNavigationTitle",
        path: "/admin/registrationperiod",
        page: "RegistrationPeriod",
        subpage: "RegistrationPeriod",
      },
    ]}
  />
);

export default RegistrationPeriodMenu;
